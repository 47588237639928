import Vue from "vue"
import axios from 'axios'

import { query, collection, where, setDoc, doc, getDoc, increment, Timestamp } from "firebase/firestore"
import { db } from '@/db'

export default Vue.mixin({
	methods: {
		
		async eventCanCheckInByCode(code, event){
			// limpio el codigo y dejo solo numeros y letras
			code = code.replace(/[^a-zA-Z0-9]/g, '')
			console.log("eventCanCheckInByCode", code)
			

			// ++++++++ busco el codigo en el store ++++++++
			// const accessCodes = await this.$store.getters.accessCodes
			// const accessCode = accessCodes.find(ac => ac.code === code)
			// if(!accessCode) return { status:'NOT_ALLOWED', headerTitle:'Código no encontrado', headerColor:'#e73d3d', error:true, errorMessage: `Código no encontrado` }
			// ++++++++ busco el codigo en el store ++++++++

			// ++++++++  busco el codigo directo en Firebase ++++++++
			const docRef = doc(db, "events", this.$store.getters.loggedData.eventCode, "accessCodes", code);
			const docSnap = await getDoc(docRef);
			if (!docSnap.exists()) return { status:'NOT_ALLOWED', headerTitle:'Código no encontrado', headerColor:'#e73d3d', error:true, errorMessage: `Código no encontrado` }
			const accessCode = docSnap.data();
			if(!accessCode.code)  return { status:'NOT_ALLOWED', headerTitle:'Código no encontrado', headerColor:'#e73d3d', error:true, errorMessage: `Código no encontrado` }

			// ++++++++  busco el codigo directo en Firebase ++++++++

			return this.eventCanCheckIn(accessCode, event)
		},

		async eventCanCheckIn(accessCode, event){

			// console.log("eventCanCheckIn")

			if(event.filterByBlackList){
				// find if accessCode holder document exists in blackList
				const blackList = this.$store.getters.blackList
				// console.log("blackList", blackList)
				// console.log("accessCode", accessCode.holder)

				// const blackListed = blackList.find(bl => bl.documentNumber == accessCode.holder.documentNumber && bl.country == accessCode.holder.documentType) ? true : false
				const blackListed = blackList.find(bl => bl.documentNumber == accessCode.holder.documentNumber) ? true : false
				if(blackListed){
					return { ...accessCode, headerTitle:'Inhabilitado', headerColor:'#e73d3d', status:'NOT_ALLOWED', error:true, errorMessage: `El portador de la entrada se encuentra INHABILITADO para ingresar a escenarios deportivos por la Asociación Uruguaya de Fútbol.` }
				}
			}

			// controlo que el codigo no haya sido utilizado
			if ( accessCode.checkedIn ){
				// levanto el checkIn del accessCode para ver cuando y con quien ingreso.
				// const checkIn = this.$store.getters.checkIns.find(ac => ac.code === accessCode.code)

				const docRef = doc(db, "events", this.$store.getters.loggedData.eventCode, "checkIns", accessCode.code);
				const docSnap = await getDoc(docRef);
				const checkIn = docSnap.data();

				if(checkIn){
					return { 
						...accessCode,
						status:'NOT_ALLOWED',
						headerTitle: 'Ya ingresado',
						headerColor:'#e73d3d',
						error:true, 
						errorMessage: `El código ya ha sido utilizado.<br><small>Ingreso a las ${this.$moment(checkIn.checkIn.checkInAt).format("HH:mm")} por: ${checkIn.checkIn.validator.name}</small>` 
					}
				}
				return { ...accessCode, status:'NOT_ALLOWED', headerTitle: 'Código ultilizado',headerColor:'#e73d3d', error:true, errorMessage: `El código ya ha sido utilizado.` }
			}

			// controlo que el validador, este validando este tipo de entrada
			const availableTicketTypes = this.$store.getters.loggedData.ticketTypes
			const type = accessCode.ticketType ? accessCode.ticketType.id : accessCode.membershipType ? accessCode.membershipType.id : null
			if((accessCode.ticketType || accessCode.membershipType) && !availableTicketTypes.includes(type)){
				return { ...accessCode, status:'WARNING', headerTitle:'Sector equivocado', headerColor:'#edad24', error:true, errorMessage: `Esta entrada pertenece a otro sector.`, canCheckInAnyway:true }
			}
			
    		if ( accessCode.validAt && this.$moment(accessCode.validAt).isBefore(this.$moment())){
				return { ...accessCode, status:'WARNING', headerTitle:'Expirado', headerColor:'#97A828', error:true, errorMessage: `El código ya ha expirado <br><small>${this.$moment(accessCode.validAt).format("DD-MM-YYYY HH:mm")} </small>`, canCheckInAnyway:true }
			}

			return { ...accessCode, headerTitle:"Acceso habilitado",status:'SUCCESS', headerColor:'#0DB271',}
		},

		async eventCodeReaded(accessCode){
			if(accessCode) setDoc(doc(db, "events", this.$store.getters.loggedData.eventCode, 'accessCodes', accessCode), { scanCount: increment(1) }, { merge: true });
		},

		async eventCheckIn(accessCode){
			// console.log("eventCheckIn", this.$store.getters.loggedData)
			// const originAccessCode = this.$store.getters.accessCodes.find(ac => ac.code === accessCode.code)
			// originAccessCode.checkedIn = true

			const lastUpdateAt = Timestamp.now()
			console.log("eventCheckIn lastUpdateAt", lastUpdateAt)

			setDoc(doc(db, "events", this.$store.getters.loggedData.eventCode, 'checkIns', accessCode.code), {
				...accessCode,
				checkIn: {
					checkInAt: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
					validator: {
						id: this.$store.getters.loggedData.id,
						name: this.$store.getters.loggedData.name,
					}
				},
				checkInAt:lastUpdateAt
			})
			.then( async(accessCodeRef) => {
				// update accessCode with checkins, checkedIn y lastUpdateAt 
				setDoc(doc(db, "events", this.$store.getters.loggedData.eventCode, 'accessCodes', accessCode.code), { 
					checkIns: increment(1),
					checkedIn: true,
					lastUpdateAt
				}, { merge: true });
				return true
			})
			.catch(error => {
				console.log(error);
				return false
			})
		}
	}
})
