import Vue from "vue"
import Vuex from "vuex"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./registerServiceWorker"

Vue.use(Vuex)

import BootstrapVue from "bootstrap-vue"
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
Vue.use(BootstrapVue)

import VueInstaller from "vue-pwa-installer"
Vue.use(VueInstaller, {})


import VueQrcodeReader from "vue-qrcode-reader"
Vue.use(VueQrcodeReader)

import VueToast from "vue-toast-notification"
import "vue-toast-notification/dist/theme-default.css"
Vue.use(VueToast, {
	position: "top-right"
})


import omitDeep from "omit-deep"
Vue.prototype.$omitDeep = omitDeep

import VuejsDialog from "vuejs-dialog"
import "vuejs-dialog/dist/vuejs-dialog.min.css"
Vue.use(VuejsDialog, {
	html: true,
	okText: "Confirmar",
	cancelText: "Cancelar",
	animation: "fade"
})

require("@/assets/fontastic/styles.css")
require("@/assets/css/animate.css")
require("@/assets/css/forms.css")
require("@/assets/css/cards.css")
require("@/assets/css/custom.css")

import Ripple from "vue-material-design-ripple"
import "vue-material-design-ripple/dist/vue-material-design-ripple.css"
Vue.directive("ripple", Ripple)

let moment = require("moment-timezone")
require("moment/locale/es")
// moment.tz.setDefault("Etc/UTC")
Vue.use(require("vue-moment"), { moment })


import VueGeolocation from "vue-browser-geolocation"
Vue.use(VueGeolocation)


import Loader from "@/components/layout/Loader"
Vue.component("Loader", Loader)

Vue.config.productionTip = false

new Vue({
	router,
	store,	
	render: (h) => h(App)
}).$mount("#app")
