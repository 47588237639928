import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		packageVersion: process.env.PACKAGE_VERSION || '0',
		authState: {
			loggedData: JSON.parse(localStorage.getItem('logged-access')) || '',
			status: 'notlogged',
		},
		settings: JSON.parse(localStorage.getItem('settings')) || {},
		currentPosition: JSON.parse(localStorage.getItem('currentPosition')) || {},
		currentEvent: {},
		accessCodes: [],
		checkIns: [],
		blackList: [],
	},

	getters: {
		isAuthenticated: (state) => state.authState.status === 'logged',
		loggedData: (state) => state.authState.loggedData,
		settings: (state) => state.settings,
		currentPosition: (state) => state.currentPosition,
		currentEvent: (state) => state.currentEvent,
		accessCodes: (state) => state.accessCodes,
		checkIns: (state) => state.checkIns,
		blackList: (state) => state.blackList,
		appVersion: (state) => state.packageVersion
	},

	mutations: {
		savePosition(state, position) {
			localStorage.setItem('currentPosition', JSON.stringify(position))
			state.currentPosition = position
		},

		authSuccess(state, { loggedData}) {
			console.log('authSuccess', loggedData)
			state.authState.status = 'logged'
			state.authState.loggedData = loggedData
			localStorage.setItem('logged-access', JSON.stringify(loggedData))
		},
		
		logout(state) {
			state.authState.loggedData = {}
			state.authState.status = 'notlogged'
			state.accessCodes = []
			localStorage.removeItem('logged-access')
		},

		saveSettings(state, settings) {
			localStorage.setItem('settings', JSON.stringify(settings))
			state.settings = settings
		},

		saveCurrentEvent(state, currentEvent) {
			state.currentEvent = currentEvent
		},

		saveCheckIns(state, checkIns) {
			state.checkIns = checkIns
		},

		saveAccessCodes(state, accessCodes) {
			state.accessCodes = accessCodes
		},

		saveBlackList(state, blackList) {
			state.blackList = blackList
			localStorage.setItem('blackList', JSON.stringify(blackList))
			console.log("blacklist saved to store")
		},

		setTicketTypes(state, ticketTypes) {
			const loggedData = {...state.authState.loggedData, ticketTypes, ticketTypesSelected:true}
			state.authState.loggedData = loggedData
			localStorage.setItem('logged-access', JSON.stringify(loggedData))
		}

	},
})
