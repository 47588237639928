<template>
	<div
		class="loader-box animated bg-00dp faster fadeIn"
		:class="{ inline: type == 'inline', spinner: type == 'spinner' }"
		v-if="show"
	>
		<div class="loader">
			<div class="icon" v-if="type != 'spinner'"></div>
			<div class="icon icon-load-c spin" v-if="type == 'spinner'"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Loader",
	props: ["show", "type"],
	methods: {}
}
</script>

<style scoped>
.loader-box {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 2500;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	margin: auto;

	width: 46px;
	height: 46px;

	display: flex;
	justify-content: center;
	align-items: center;
}
.icon {
	width: 100%;
	height: 100%;
	background: url(~@/assets/img/loader.gif);
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	filter: invert(0.86);
	/* opacity: 0.5; */
}
.loader-box.inline {
	width: 100%;
	height: auto;
	position: relative;
	background: transparent !important;
}
.loader-box.inline .loader {
	width: 46px;
	height: 46px;
}

.loader-box.spinner {
	width: 32px;
	height: auto;
	position: relative;
	background: transparent !important;
}
.loader-box.spinner .loader {
	width: 32px;
	height: 32px;
}
.loader-box.spinner .icon {
	width: 24px;
	height: 24px;
	background: none !important;
	font-size: 24px;
}
</style>
