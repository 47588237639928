<template>
    <div>
        <vue-internet-checker
            @status="status"
            @event="event"
        />
        <div class="alert-offline" v-if="!onLine">
            <h4 class="d-flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-access-point-off" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="3" y1="3" x2="21" y2="21"></line>
                    <path d="M14.828 9.172a4 4 0 0 1 1.172 2.828"></path>
                    <path d="M17.657 6.343a8 8 0 0 1 1.635 8.952"></path>
                    <path d="M9.168 14.828a4 4 0 0 1 0 -5.656"></path>
                    <path d="M6.337 17.657a8 8 0 0 1 0 -11.314"></path>
                </svg>
                Trabajando sin conexión. 
            </h4>

            <div>Puedes continuar trabajando, pero no cierres ni apagues la aplicación hasta que se restablezca la conexión para evitar perder datos.</div>
        </div>
    </div>

</template>

<script>
import vueInternetChecker from 'vue-internet-checker'
export default {
    name: "NetworkState",
    components:{
        vueInternetChecker
    },
    data: () => ({
      onLine: true,
    }),
    methods: {
        status(ele) {
            console.log("Internet checker status", ele);
            this.onLine = ele;
        },
        event(ele) {
            console.log('internet checker event', ele);
        },
    },

}
</script>

<style lang="scss" scoped>
.alert-offline{


    text-align: center;
    color: rgb(142, 142, 142);
    font-size: 11px;
    padding: 8px;
    background-color: #27272720;
    line-height: 18px;
    position: fixed;
    bottom: 90px;
    width: 100%;
    
    h4{
        font-size: 13px;
        font-weight: 300;
        width: 100%;
        text-align: center;
        justify-content: center;
    }
    svg{
        margin-right: 4px;
    }
}
// .alert-offline{
//     color: #fff;
//     font-size: 13px;
//     padding: 8px;
//     background-color: #f48536;
//     line-height: 18px;
    
//     h4{
//         font-size: 15px;
//     }
//     svg{
//         margin-right: 4px;
//     }
// }

// .alert-offline{
//     backdrop-filter: blur(2px);
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background-color: rgba(0,0,0,0.5);
//     z-index: 9999;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     color: #fff;
//     font-size: 1.4rem;
//     font-weight: bold;
//     text-align: center;
    
//     transition: opacity 0.5s ease-in-out;
// }
</style>
