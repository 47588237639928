import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

let router = new Router({
	mode: "history",
	routes: [
		{
			path: "/login",
			name: "Login",
			component: () => import(/* webpackChunkName: "Login" */ "@/views/Login")
		},
		{
			path: "/logout",
			name: "Logout",
			component: () => import(/* webpackChunkName: "Logout" */ "@/views/Logout")
		},
		{
			path: "/e/:id",
			name: "EventDetail",
			meta: {
				requiresAuth: true,
				section: "events"
			},
			component: () => import(/* webpackChunkName: "EventDetail" */ "@/views/event/EventDetail")
		},
		{
			path: "*",
			redirect: "/login"
		}
	]
})

router.beforeEach((to, from, next) => {
	var loggedIn = localStorage.getItem("logged-access")
	loggedIn = JSON.parse(loggedIn)
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (loggedIn) {
			next()
			return
		}
		next("/login")
	} else {
		next()
	}
})

export default router
