<template>
	<div class="" v-if="showPrompt && $installer && $installer.canInstall && !$installer.hasInstalled">
		<div class="dg-backdrop"></div>
		<div class="dg-container">
			<div class="dg-content-cont dg-content-cont--floating">
				<div class="dg-main-content">
					<div class="dg-view-wrapper">
						<div class="dg-content-body dg-content-body--has-title">
							<h6 class="dg-title">Instalar aplicación</h6>
							<div class="dg-content">
								¿Desea instalar la aplicación en este dispositivo?
							</div>
						</div>
						<div class="dg-content-footer">
							<button class="dg-btn dg-btn--cancel" @click="showPrompt = false">Cancelar</button>
							<button class="dg-btn dg-btn--ok dg-pull-right" @click="$installer.prompt">Instalar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
	name: "InstallApp",

	data() {
		return {
			showPrompt: false
		}
	},
	watch: {
		$installer: {
			deep: true,
			handler() {
				if (this.$installer && this.$installer.canInstall && !this.$installer.hasInstalled) {
					this.installApp()
				}
			}
		}
	},
	computed: {
		...mapGetters(["isAuthenticated"])
	},
	methods: {
		installApp() {
			if (this.isAuthenticated) {
				this.showPrompt = true
			}
		}
	}
}
</script>
