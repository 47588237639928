<template>
	<div id="app">
		<router-view :key="$route.fullPath"></router-view>
		<InstallApp />
		<!-- <NetworkState /> -->
		
	</div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex"
import mixins from "@/mixins/mixins"
import factory from "@/mixins/factory"
import InstallApp from "./components/InstallApp"
import NetworkState from "./components/NetworkState"

export default {
	name: "App",
	components: { InstallApp, NetworkState },
	mixins: [mixins, factory],
	beforeMount() {
		this.checkNFC()
		this.watchLocation()
	},
	data() {
		return {
			iHaveToCheckOfficePosition: true, // checkeo que la ubicacion del dspositivo este cerca de la oficina asignada al usuario, sino pido que la cambie.
			refreshing: false,
			registration: null,
			updateExists: false,
		}
	},
	computed: {
		...mapGetters(["isAuthenticated", "loggedData","settings"])
	},
	
	
	created(){
		document.addEventListener(
			'swUpdated', this.showRefreshUI, { once: true }
		);
		if (navigator.serviceWorker) {  
			navigator.serviceWorker.addEventListener('controllerchange', () => {
			if (this.refreshing) return;
				this.refreshing = true;
				window.location.reload();
			})
		}
	},

	methods: {
		...mapMutations(["logout", "savePosition","saveSettings"]),

		checkNFC(){
			console.log('startNFC')
			let nfcSupoorted = false
			if ('NDEFReader' in window) {
				nfcSupoorted = true
			
			}
			this.saveSettings({
				...this.settings,
				nfcSupoorted
			})
		},

		watchLocation() {
			if (this.isAuthenticated) {
				this.$watchLocation({ enableHighAccuracy: true, timeout: 5000 })
					.then((coordinates) => {
						this.savePosition({
							lat: coordinates.lat,
							lng: coordinates.lng
						})
					})
					.catch((err) => {
						console.log(err)
						// this.$toast.error('Debe aceptar los permisos de ubicación')
					})
			}
		},


		refreshApp () {
			this.updateExists = false;
			if (!this.registration || !this.registration.waiting) { return; }
			this.registration.waiting.postMessage('skipWaiting');
		},
		
	}
}
</script>
