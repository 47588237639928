import firebase from 'firebase/compat/app'
import { getFirestore, enableIndexedDbPersistence, CACHE_SIZE_UNLIMITED, clearIndexedDbPersistence } from 'firebase/firestore'
import  "firebase/compat/auth";

let firebaseConfig = {
    apiKey: 'AIzaSyBKiaCHNRpHPZQGl004zO3AAnG_lobiU4Q',
    authDomain: 'staging-cobra.firebaseapp.com',
    projectId: 'staging-cobra',
    storageBucket: 'staging-cobra.appspot.com',
    messagingSenderId: '977044511846',
    appId: '1:977044511846:web:b9d6be986bd18f8b60e1a7',
}
if (process.env.NODE_ENV == 'production') {
    firebaseConfig = {
        apiKey: 'AIzaSyBLYFYAwUfuqyJ49ocP5qM2pDsAWyng3xQ',
        authDomain: 'api-cobra.firebaseapp.com',
        projectId: 'api-cobra',
        storageBucket: 'api-cobra.appspot.com',
        messagingSenderId: '673083476281',
        appId: '1:673083476281:web:481d5736618117043ac0e8',
        measurementId: 'G-JB7MZHHF65',
    }
}


const appDatabase = firebase.initializeApp(firebaseConfig, {
    cacheSizeBytes: CACHE_SIZE_UNLIMITED
})


const db = getFirestore(appDatabase)
const auth = appDatabase.auth()

clearIndexedDbPersistence(db)

enableIndexedDbPersistence(db)
.then(() => {
    console.log('Firestore persistence enabled', db.app.options.projectId)
})
.catch((err) => {
    console.log("Error enabling indexedDb persistence", err)
    if (err.code == 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        alert('Multiple tabs open, persistence can only be enabled in one tab at a a time.')
    } else if (err.code == 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        alert('The current browser does not support all of the features required to enable persistence')
    }else{
        alert('Error enabling indexedDb persistence')
    }
});

export { db, auth }
